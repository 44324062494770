<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.delimiters') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('delimiters.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('price_lists.name')"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">

              <div class="col-lg-6 mb-5">
                <label>{{ $t('delimiters.tax_rule') }}<span class="text-danger">*</span></label>
                <select name="" id="tax_rule" v-model="data.tax_rule_id" class="custom-select">
                  <option v-for="row in tax_rule_list" :value="row.id" :key="row.id">
                    {{ row.name }}
                  </option>
                </select>
                <span v-if="validation && validation.tax_rule" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_rule[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row">

        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('delimiters.options') }}</h6>
            <button type="button" class="btn btn-primary" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="form-group">
            <div class="bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th>{{ $t('delimiters.name') }}</th>
                  <th>{{ $t('delimiters.value') }}</th>
                  <th class="text-center">{{ $t("actions") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in options_list" :key="index">
                  <td>
                    <input type="text" v-model="it.name" class="form-control" :placeholder="$t('delimiters.name')"/>
                  </td>
                  <td>
                    <input type="text" v-model="it.value" class="form-control" :placeholder="$t('delimiters.value')"/>
                  </td>
                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('delimiters.description') }}</label>
                  <textarea type="text" v-model="data.description" class="form-control" :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.description[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('delimiters.example') }}</label>
                  <textarea type="text" v-model="data.example" class="form-control" :class="validation && validation.example ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.example" class="fv-plugins-message-container invalid-feedback">
                          {{ validation.example[0] }}
                  </span>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('delimiters.attachment')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('delimiters.attachment') }}</label>
                  <upload-file
                      @file="listenerAttachment"
                      :inner-id="'attachment'"
                      :placeholder="$t('delimiters.attachment')"
                      :upload="dir_upload"
                      :start-link="'base'"
                      v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                  </upload-file>
                  <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.attachment[0] }}
                                </span>
                </div>              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-delimiters",
  data() {
    return {
      mainRoute: 'settings/tax_rules_details',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'tax_rules_details',



      tax_rule_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        name: null,
        tax_rule_id: null,
        description: null,
        example: null,
        attachment: null,
        is_active: true,
      },
      isEditing: false,
      validation: null,
      attachment_url: null,
      reloadUploadAttachment: true,
      options_list_repeater: {id: null, name: null, value: null},
      options_list: [],
    };
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        options: this.options_list,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/delimiters');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        options: this.options_list,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/delimiters');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.tax_rule_id = response.data.data.tax_rule_id;
        this.data.description = response.data.data.description;
        this.data.example = response.data.data.example;
        this.data.attachment = response.data.data.attachment;
        this.data.is_active = response.data.data.is_active;
        this.attachment_url = response.data.data.attachment_url;
        this.reloadUploadAttachment = true;

        this.options_list = response.data.data.options;
        if (response.data.data.options.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getTaxRule() {
      ApiService.get(this.mainRouteDependency + "/tax_rules").then((response) => {
        this.tax_rule_list = response.data.data;
      });
    },

    addToRepeater() {
      this.options_list.unshift(this.options_list_repeater);
      this.options_list_repeater = {id: null, name: null, value: null};
    },
    deleteFromRepeater(index) {
      if (this.options_list.length > 1) {
        this.options_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.delimiters"), route: '/settings/delimiters'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getTaxRule();
    if (this.idEdit) {
      this.getData();
    } else {
      this.addToRepeater();
    }

  },
};
</script>


